import React, { useState } from "react";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import icon_user from "../../images/icon_user.png";
import { useHistory } from "react-router-dom";
import "./UserProfileFavorites.css";
import StickyFooter from "../../helpers/StickyFooter/StickyFooter";
import authService from "../../services/auth.service";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import patientsService from "../../services/patients.service";
import { notify } from "../../helpers/Notifications/Notifications";
import { IoIosArrowBack } from "react-icons/io";

export const UserProfileFavorites = () => {
  const history = useHistory();
  const [activeLink, setActiveLink] = useState(0);
  const [favoriteDoctors, setFavoriteDoctors] = useState([]);
  const [
    favoriteHealthcareEstablishments,
    setFavoriteHealthcareEstablishments,
  ] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getAuthenticatedUserBookmarks();
  }, []);

  const getAuthenticatedUserBookmarks = async () => {
    try {
      let response = await authService.getAuthenticatedUserBookmarks();
      // const children = response.data?.favoriteDoctors.concat(response.data?.favoriteHealthcareEstablishments);
      setFavoriteDoctors(response.data?.favoriteDoctors);
      setFavoriteHealthcareEstablishments(
        response.data?.favoriteHealthcareEstablishments
      );
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const PatientBookmark = async (id, typedata) => {
    try {
      let data = {};
      if (typedata) {
        data = {
          doctorId: id,
        };
      } else {
        data = {
          establishmentId: id,
        };
      }
      await patientsService.PatientBookmark(data);
      getAuthenticatedUserBookmarks();
    } catch (error) {
      if (error.response.status === 401) {
        notify({ msg: "Merci de vous authentifier", type: "warning" });
      }
      console.log(error);
    }
  };

  return (
    <div className="UserProfileFavorites_container">
      <div className="UserProfileIfo_header">
        <div className="UserProfileIfo_header_lil_container">
          <IoIosArrowBack
            size={20}
            color="#284358"
            onClick={() => history.goBack()}
          />
          <p className="UserProfileIfo_header_title">Mes Pages favorites</p>
        </div>
        <p></p>
      </div>
      <div
        className="DoctorView_scroll_y"
        style={{
          paddingTop: "0px",
          marginTop: "0px",
          justifyContent: "flex-start",
        }}
      >
        <div
          className={
            activeLink === 0
              ? "DoctorView_scroll_y_content_active"
              : "DoctorView_scroll_y_content"
          }
          onClick={() => setActiveLink(0)}
        >
          <p
            className={
              activeLink === 0
                ? "DoctorView_scroll_y_content_text_active"
                : "DoctorView_scroll_y_content_text"
            }
          >
            Médecin
          </p>
        </div>
        <div
          className={
            activeLink === 1
              ? "DoctorView_scroll_y_content_active"
              : "DoctorView_scroll_y_content"
          }
          onClick={() => setActiveLink(1)}
          style={{ marginLeft: "20px" }}
        >
          <p
            className={
              activeLink === 1
                ? "DoctorView_scroll_y_content_text_active"
                : "DoctorView_scroll_y_content_text"
            }
          >
            Établissement de santé
          </p>
        </div>
      </div>
      {activeLink === 0 && (
        <div className="UserProfileFavorites_content">
          {favoriteDoctors.length !== 0 &&
            favoriteDoctors.map((item, key) => {
              return (
                <>
                  <DoctorsData
                    data={item}
                    key={key}
                    onClick={() => PatientBookmark(item?.id, true)}
                  />
                </>
              );
            })}
        </div>
      )}

      {activeLink === 1 && (
        <div className="UserProfileFavorites_content">
          {favoriteHealthcareEstablishments.length !== 0 &&
            favoriteHealthcareEstablishments.map((item, key) => {
              return (
                <>
                  <EstablishmentData
                    data={item}
                    key={key}
                    onClick={() => PatientBookmark(item?.id, false)}
                  />
                </>
              );
            })}
        </div>
      )}
      {loader && <DocDataLoader />}
      <StickyFooter />
    </div>
  );
};

export const DoctorsData = (props) => {
  const history = useHistory();
  const data = props.data;
  return (
    <div className="DoctorsOfficeSearchResult">
      <div
        className="DoctorsOfficeSearchResult_container"
        style={{ alignItems: "self-start" }}
      >
        <img src={icon_user} />
        <div className="DoctorsOffice_content">
          <p className="doctors_name">
            {data?.firstName
              ? (data?.firstName ?? "") + " " + (data?.lastName ?? "")
              : data.name}
          </p>
          <p className="doctors_specialty">{data?.speciality?.label ?? ""}</p>
        </div>
        <AiTwotoneHeart size={25} color="#F4AC2F" onClick={props.onClick} />
      </div>
      <div>
        {/* <button className='DoctorsOfficeSearchResult_button'><GoCalendar size={20} color='#00b2d8' />Prendre un rendez-vous</button> */}
        <button
          className="DoctorsOfficeSearchResult_button"
          onClick={() => history.push(`/doctor/details/${data?.slug}`)}
        >
          Plus de détails
        </button>
      </div>
    </div>
  );
};

export const EstablishmentData = (props) => {
  const history = useHistory();
  const data = props.data;
  return (
    <div className="DoctorsOfficeSearchResult">
      <div
        className="DoctorsOfficeSearchResult_container"
        style={{ alignItems: "self-start" }}
      >
        <img src={icon_user} />
        <div className="DoctorsOffice_content">
          <p className="doctors_name">{data.name ?? ""}</p>
          {data?.address && (
            <p className="doctors_address">
              <IoLocationOutline size={15} />
              {data?.address}
            </p>
          )}
        </div>
        <AiTwotoneHeart size={25} color="#F4AC2F" onClick={props.onClick} />
      </div>
      <div>
        {/* <button className='DoctorsOfficeSearchResult_button'><GoCalendar size={20} color='#00b2d8' />Prendre un rendez-vous</button> */}
        <button
          className="DoctorsOfficeSearchResult_button"
          onClick={() => history.push(`/establishment/details/${data?.slug}`)}
        >
          Plus de détails
        </button>
      </div>
    </div>
  );
};

export const DocDataLoader = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "1.3rem",
        backgroundColor: "#ffffff",
        padding: "1rem",
        marginTop: "10px",
      }}
    >
      <ContentLoader
        speed={2}
        width={"100%"}
        height={120}
        viewBox="0 0 250 100"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="48" y="7" rx="3" ry="3" width="320" height="7" />
        <rect x="48" y="26" rx="3" ry="3" width="314" height="7" />
        <rect x="7" y="63" rx="3" ry="3" width="175" height="22" />
        <circle cx="20" cy="20" r="20" />
        <rect x="191" y="62" rx="3" ry="3" width="175" height="22" />
      </ContentLoader>
    </div>
  );
};

const initialState = { currentPathName: "/" };

function handleCurrentPath(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "HANDLE_CURRENT_PATH":
      nextState = {
        ...state,
        currentPathName: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default handleCurrentPath;

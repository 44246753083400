import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "establishments",
});

class EstablishmentsService {
  constructor() {}

  getEstablishment(id) {
    return authAxios.get(`/${id}`, {});
  }
}

export default new EstablishmentsService();

import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "cities",
});

class CitiesService {
  constructor() {}

  getCities(search) {
    let params = {
      label: search,
    };
    return authAxios.get(``, { params });
  }
}

export default new CitiesService();

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class failureComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: this.props.location.search,
    };
  }

  componentDidMount() {}

  verifyError = () => {
    if (this.state.error === "?error=code1") {
      localStorage.clear();
      return <Redirect to={"/Login"} />;
    } else if (this.state.error === "?error=code2") {
      localStorage.clear();
      return <Redirect to={"/Login"} />;
    } else if (this.state.error === "?error=code3") {
      localStorage.clear();
      return <Redirect to={"/Login"} />;
    } else if (this.state.error === "?error=code5") {
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              message:
                "Votre compte Chez le doc semble être introuvable ou désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com).",
              codeError: "code 5",
            },
          }}
        />
      );
    } else if (this.state.error === "?error=code6") {
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              message:
                "Votre compte MED semble être introuvable ou désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com).",
              codeError: "code 6",
            },
          }}
        />
      );
    } else if (this.state.error === "?error=code7") {
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              message:
                "Votre compte MED semble être introuvable ou désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com).",
              codeError: "code 7",
            },
          }}
        />
      );
    }
  };

  render() {
    return this.verifyError();
  }
}

export default failureComponent;

const initialState = { currentHealthCareEstablishment: null };

function currentHealthCareEstablishment(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "SET_HEALTH_CARE_ESTABLISHMENT":
      nextState = {
        ...state,
        currentHealthCareEstablishment: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default currentHealthCareEstablishment;

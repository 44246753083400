import React, { Component } from "react";
import moment from "moment";
import "./openingHours.css";
import "moment/locale/fr";

class openingHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: "",
    };
  }

  componentWillMount() {
    const today = moment().locale("fr").format("dddd");
    this.setState({ today });
  }

  render() {
    const { openingHours } = this.props;

    console.log("openingHours", openingHours);
    return (
      <div className="openingHours">
        {openingHours.length !== 0 &&
          openingHours?.map((item, key) => {
            return (
              item && (
                <div
                  className={
                    this.state.today.charAt(0).toUpperCase() +
                      this.state.today.slice(1) ===
                    item?.dayOfWeek
                      ? "about_horaire_bold"
                      : " about_horaire"
                  }
                  key={key}
                >
                  <div className="day">
                    <p>
                      {item?.dayOfWeek === 0
                        ? "Lundi"
                        : item?.dayOfWeek === 1
                        ? "Mardi"
                        : item?.dayOfWeek === 2
                        ? "Mercredi"
                        : item?.dayOfWeek === 3
                        ? "Jeudi"
                        : item?.dayOfWeek === 4
                        ? "Vendredi"
                        : item?.dayOfWeek === 5
                        ? "Samedi"
                        : item?.dayOfWeek === 6
                        ? "Dimanche"
                        : item?.dayOfWeek}
                    </p>
                  </div>

                  {item?.dayOfWeek === "Dimanche" || item?.dayOfWeek === 6 ? (
                    <div className="day" style={{ textAlign: "center" }}>
                      <p></p>
                    </div>
                  ) : (
                    <div className="day_time">
                      <p>
                        {(item?.startOne?.substring(0, 5) ?? " ") +
                          " - " +
                          (item?.endOne?.substring(0, 5) ?? " ")}
                      </p>
                    </div>
                  )}
                  {item?.dayOfWeek === "Samedi" ||
                  item?.dayOfWeek === 5 ||
                  item?.dayOfWeek === "Dimanche" ||
                  item?.dayOfWeek === 6 ? (
                    <div
                      className="day"
                      style={{ textAlign: "center", width: "22%" }}
                    >
                      <p></p>
                    </div>
                  ) : (
                    <div className="day_time">
                      <p>
                        {(item?.startTwo?.substring(0, 5) ?? " ") +
                          " - " +
                          (item?.endTwo?.substring(0, 5) ?? " ")}
                      </p>
                    </div>
                  )}
                </div>
              )
            );
          })}
      </div>
    );
  }
}

export default openingHours;

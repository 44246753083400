import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "doctors",
});

class DoctorService {
  constructor() {}

  getDoctor(id) {
    return authAxios.get(`/${id}`, {});
  }
}

export default new DoctorService();

import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "specialities",
});

class SpecialityService {
  constructor() {}

  getSpecialities(search) {
    let params = {
      speciality: search,
      page: 1,
      limit: 5,
    };
    return authAxios.get(``, { params });
  }
}

export default new SpecialityService();

import authService from "../services/auth.service";
import Store from "../Store/configStore";

class DataInMount {
  getAuthenticatedUser = async () => {
    try {
      let response = await authService.getAuthenticatedUser();
      Store.dispatch({ type: "SET_USER_DATA", value: response?.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export default new DataInMount();

import React, { Component } from "react";
import Logo from "../../images/Logo.png";
import spriteEmail from "../../images/spriteEmail.png";
import spriteLocalisation from "../../images/spriteLocalisation.png";
import spritePhone from "../../images/spritePhone.png";
import facebook from "../../images/facebook.png";
import Linkedin from "../../images/Linkedin.png";
import Twitter from "../../images/Twitter.png";
import sobrus_logo from "../../images/sobrus_logo.png";
import Site_icon from "../../images/Site_icon.png";
import Instagram from "../../images/Instagram.png";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <img src={Logo} className="Footer_logo" />
        <p className="Footer_paragraphe">
          Le premier annuaire interactif de médecins au Maroc 100% gratuit. Cet
          outil permet de rapprocher le médecin de ses patients et de rendre
          accessibles toutes les informations des médecins pour garantir une
          meilleure santé.
        </p>
        <div className="Footer_links_container">
          <div className="Footer_links_container_first_section">
            <p className="Footer_links_container_first_section_title">
              Nos solutions
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="http://pharma.sobrus.com/"
                target="_blank"
              >
                Sobrus Pharma
              </a>
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="http:/labsv3.sobrus.com"
                target="_blank"
              >
                Sobrus Labs
              </a>
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="https://sns.sobrus.com/"
                target="_blank"
              >
                Sobrus SNSS
              </a>
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="https://med.sobrus.com/"
                target="_blank"
              >
                Sobrus MED
              </a>
            </p>
          </div>
          <div className="Footer_links_container_first_section">
            <p className="Footer_links_container_first_section_title">
              Nos outils
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="http://monmedicament.ma/"
                target="_blank"
              >
                {" "}
                Annuaire Médicaments
              </a>
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="https://monordonnance.ma/"
                target="_blank"
              >
                Ordonnance Électronique
              </a>
            </p>
            <p>
              <a
                className="Footer_links_container_first_section_paragraphe"
                href="http://chezledocteur.ma/"
                target="_blank"
              >
                Annuaire Médecins{" "}
              </a>
            </p>
          </div>
          <div
            className="Footer_links_container_first_section"
            style={{ width: "100%" }}
          >
            <p className="Footer_links_container_first_section_title">
              Contactez-nous
            </p>
            <p className="Footer_links_container_first_section_paragraphe contact_section">
              <img src={spritePhone} />
              0530 500 500
            </p>
            <p className="Footer_links_container_first_section_paragraphe contact_section">
              <img src={Site_icon} />
              support@sobrus.com
            </p>
            <p className="Footer_links_container_first_section_paragraphe contact_section">
              <img src={spriteEmail} />
              sobrus.com
            </p>
            <p className="Footer_links_container_first_section_paragraphe contact_section">
              <img src={spriteLocalisation} />
              Villa N°222, rue Tetouan, Harhoura
            </p>
          </div>
        </div>
        <div className="Footer_icons_container">
          <div
            className="Footer_icons"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/showcase/sobrus-med/",
                "_blank"
              )
            }
          >
            <img src={Linkedin} className="" />
          </div>
          <div
            className="Footer_icons"
            onClick={() =>
              window.open(
                "https://www.facebook.com/ApplicationSobrusMED",
                "_blank"
              )
            }
          >
            <img src={facebook} className="" />
          </div>
          <div
            className="Footer_icons"
            onClick={() =>
              window.open("https://www.instagram.com/sobrusmed/", "_blank")
            }
          >
            <img src={Instagram} className="" />
          </div>
        </div>
        <div className="Footer_offered_by_container">
          <p className="Footer_offered_by_text">Offert par</p>
          <img src={sobrus_logo} className="Footer_offered_by_image" />
        </div>
        <div className="Footer_offered_by_container">
          <p className="Footer_offered_by_text_brand">ChezLeDocteur © 2021</p>
        </div>
      </div>
    );
  }
}

export default Footer;

import React from "react";
import { useHistory } from "react-router-dom";
import default_user_image from "../../images/default_user_image.png";
import StickyFooter from "../../helpers/StickyFooter/StickyFooter";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { GoCalendar } from "react-icons/go";
import { AiOutlineHeart } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import "./UserProfile.css";
import authService from "../../services/auth.service";
import Store from "../../Store/configStore";

export const UserProfile = () => {
  const history = useHistory();

  const logOut = async () => {
    try {
      await authService.logOut();
      Store.dispatch({ type: "SET_USER_DATA", value: null });
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="UserProfile_container">
        <div className="DoctorView_header_container">
          <div></div>
          <div className="DoctorView_header_content">
            <img src={default_user_image} className="" />
            <p className="DoctorView_doc_name" style={{ marginTop: "21px" }}>
              Dr Mohammed Karimi
            </p>
            <p className="UserProfile_logout" onClick={() => logOut()}>
              Se déconnecter
            </p>
          </div>
          <div></div>
        </div>
        <div className="user_links">
          <div
            className="DoctorView_drop_down_header user_link"
            onClick={() => {
              history.push("/profile/info");
            }}
          >
            <div className="DoctorView_drop_down_header_lil_groupe">
              <FaRegUser size={20} color="#BEC6CC" />
              <p className="DoctorView_drop_down_text">
                Informations personnelles
              </p>
            </div>
            <IoIosArrowForward size={15} color="#000000" />
          </div>
          {/* <div className="DoctorView_drop_down_header user_link" onClick={() => { }}>
                        <div className="DoctorView_drop_down_header_lil_groupe">
                            <GoCalendar size={20} color='#BEC6CC' />
                            <p className="DoctorView_drop_down_text">Mes rendez-vous</p>
                        </div>
                        <IoIosArrowForward size={15} color='#000000' />
                    </div> */}

          <div
            className="DoctorView_drop_down_header user_link"
            onClick={() => {
              history.push("/profile/favorites");
            }}
          >
            <div className="DoctorView_drop_down_header_lil_groupe">
              <AiOutlineHeart size={20} color="#BEC6CC" />
              <p className="DoctorView_drop_down_text">Pages favorites</p>
            </div>
            <IoIosArrowForward size={15} color="#000000" />
          </div>

          <div
            className="DoctorView_drop_down_header user_link"
            onClick={() => {
              history.push("/profile/consulted");
            }}
          >
            <div className="DoctorView_drop_down_header_lil_groupe">
              <BsEye size={20} color="#BEC6CC" />
              <p className="DoctorView_drop_down_text">Pages consultées</p>
            </div>
            <IoIosArrowForward size={15} color="#000000" />
          </div>
        </div>
      </div>
      <StickyFooter />
    </>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { MdKeyboardArrowLeft } from "react-icons/md";
import idCard from "../../images/id-card.png";
import answer from "../../images/answer.png";
import cash from "../../images/cash.png";
import experimentResults from "../../images/experiment-results.png";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import fr_image from "../../images/fr_image.png";
import AG_image from "../../images/AG_image.png";
import ar_image from "../../images/ar_image.png";
import money from "../../images/money.png";
import Cart_bank from "../../images/Cart_bank.png";
import check from "../../images/check.png";
import GoogleMapReact from "google-map-react";
import { ImLocation } from "react-icons/im";
import OpeningHours from "../../helpers/openingHours/openingHours";
import patientsService from "../../services/patients.service";
import establishmentsService from "../../services/establishments.service";
import { notify } from "../../helpers/Notifications/Notifications";
import authService from "../../services/auth.service";
import icon_user from "../../images/icon_user.png";
import autres_icon from "../../images/autres_icon.png";
import cheque_icon from "../../images/cheque_icon.png";
import wire_transfer from "../../images/wire_transfer.png";
import money_bag_icon from "../../images/money_bag_icon.png";
import cab_defailt_image from "../../images/cab_defailt_image.png";
import Allemand from "../../images/Allemand.png";
import Chinois from "../../images/Chinois.png";
import Japonais from "../../images/Japonais.png";
import Portugais from "../../images/Portugais.png";
import Russe from "../../images/Russe.png";
import Turc from "../../images/Turc.png";
import Ukrainien from "../../images/Ukrainien.png";
import Wolof from "../../images/Wolof.png";
import Amazigh from "../../images/Amazigh.png";
import Rifain from "../../images/Rifain.png";
import ItalyFlag from "../../images/ItalyFlag.png";
import spainFlag from "../../images/spainFlag.png";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import parse from "html-react-parser";

export const EstablishmentView = () => {
  const history = useHistory();
  const [activeLink, setActiveLink] = useState(0);
  const [dropDown, setDtopDown] = useState(-1);
  const [docData, setDocData] = useState(0);
  const [
    favoriteHealthcareEstablishments,
    setFavoriteHealthcareEstablishments,
  ] = useState([]);

  const params = useParams();

  const presentationOfTheFirmRef = useRef();
  // const trainingRef = useRef();
  // const professionnalCareerRef = useRef();
  const spokenLanguagesRef = useRef();
  const careAndProceduresRef = useRef();
  const paymentMethodsAcceptedRef = useRef();

  useEffect(() => {
    getEstablishment();
    getAuthenticatedUserBookmarks();
  }, []);

  useEffect(() => {
    PatientAction("consultation");
  }, [docData]);

  const PatientAction = async (action) => {
    try {
      let data = {
        action: action,
        establishmentId: docData?.id,
      };
      await patientsService.PatientAction(data);
    } catch (error) {
      console.log(error);
    }
  };

  const PatientBookmark = async () => {
    try {
      let data = {
        establishmentId: docData?.id,
      };
      await patientsService.PatientBookmark(data);
      getAuthenticatedUserBookmarks();
    } catch (error) {
      if (error.response.status === 401) {
        notify({
          msg: "Veuillez vous connecter pour continuer",
          type: "warning",
        });
      }
      console.log(error);
    }
  };

  const getEstablishment = async () => {
    try {
      let response = await establishmentsService.getEstablishment(params?.slug);
      setDocData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAuthenticatedUserBookmarks = async () => {
    try {
      let response = await authService.getAuthenticatedUserBookmarks();
      setFavoriteHealthcareEstablishments(
        response.data?.favoriteHealthcareEstablishments
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="DoctorView">
      <div className="DoctorView_header_container">
        <MdKeyboardArrowLeft
          size={30}
          color="#FFFFFF"
          onClick={() => history.goBack()}
        />
        <div className="DoctorView_header_content">
          <div className="DoctorView_header_image_container">
            {docData?.images?.lenght !== 0 &&
              docData?.images?.map((item, key) => {
                if (item?.mainPicture) {
                  return (
                    <img src={item?.imagePath} alt={item?.name} key={key} />
                  );
                } else {
                  return <img src={cab_defailt_image} alt="default" />;
                }
              })}
          </div>
          <p className="DoctorView_doc_name"> {docData?.name ?? ""}</p>
          {docData?.address ? (
            <p className="DoctorView_doc_adress">
              <IoLocationOutline size={20} color="#fff" /> {docData?.address}
            </p>
          ) : (
            ""
          )}
        </div>
        {favoriteHealthcareEstablishments.some(
          (element) => element?.id === parseInt(docData?.id)
        ) ? (
          <AiTwotoneHeart
            size={30}
            color="#F4AC2F"
            onClick={() => PatientBookmark()}
          />
        ) : (
          <AiOutlineHeart
            size={30}
            color="#FFFFFF"
            onClick={() => PatientBookmark()}
          />
        )}
      </div>
      <div className="DoctorView_scroll_y">
        <div
          className={
            activeLink === 0
              ? "DoctorView_scroll_y_content_active"
              : "DoctorView_scroll_y_content"
          }
          onClick={() => setActiveLink(0)}
        >
          <p
            className={
              activeLink === 0
                ? "DoctorView_scroll_y_content_text_active"
                : "DoctorView_scroll_y_content_text"
            }
          >
            À propos
          </p>
        </div>
        <div
          className={
            activeLink === 1
              ? "DoctorView_scroll_y_content_active"
              : "DoctorView_scroll_y_content"
          }
          onClick={() => setActiveLink(1)}
        >
          <p
            className={
              activeLink === 1
                ? "DoctorView_scroll_y_content_text_active"
                : "DoctorView_scroll_y_content_text"
            }
          >
            Carte
          </p>
        </div>
        <div
          className={
            activeLink === 2
              ? "DoctorView_scroll_y_content_active"
              : "DoctorView_scroll_y_content"
          }
          onClick={() => setActiveLink(2)}
        >
          <p
            className={
              activeLink === 2
                ? "DoctorView_scroll_y_content_text_active"
                : "DoctorView_scroll_y_content_text"
            }
          >
            Horaire de travail
          </p>
        </div>
        {/* <div className={activeLink === 3 ? "DoctorView_scroll_y_content_active" : "DoctorView_scroll_y_content"} onClick={() => setActiveLink(3)}>
                    <p className={activeLink === 3 ? "DoctorView_scroll_y_content_text_active" : "DoctorView_scroll_y_content_text"}>Tarif</p>
                </div> */}
        <div
          className={
            activeLink === 4
              ? "DoctorView_scroll_y_content_active"
              : "DoctorView_scroll_y_content"
          }
          onClick={() => setActiveLink(4)}
        >
          <p
            className={
              activeLink === 4
                ? "DoctorView_scroll_y_content_text_active"
                : "DoctorView_scroll_y_content_text"
            }
          >
            L'équipe
          </p>
        </div>
      </div>
      {activeLink === 0 && (
        <div className="DoctorView_drop_down_container">
          <div className="DoctorView_drop_down">
            <div
              className="DoctorView_drop_down_header"
              onClick={() => {
                if (dropDown === 0) {
                  setDtopDown(null);
                } else {
                  setDtopDown(0);
                }
              }}
            >
              <div className="DoctorView_drop_down_header_lil_groupe">
                <img src={idCard} className="" alt="idCard" />
                <p className="DoctorView_drop_down_text">
                  Présentation du Cabinet
                </p>
              </div>
              <IoIosArrowUp
                size={20}
                color="#284358"
                style={{
                  transition: "all .2s",
                  transform: `rotate(${dropDown === 0 ? "180deg" : "0deg"})`,
                }}
              />
              {/*  <IoIosArrowDown size={20} color='#284358' /> */}
            </div>
            <div
              className="dropDown"
              ref={presentationOfTheFirmRef}
              style={
                dropDown === 0
                  ? {
                      height:
                        presentationOfTheFirmRef?.current?.scrollHeight + "px",
                    }
                  : {
                      height: "0px",
                    }
              }
            >
              {" "}
              <PresentationOfTheFirm docData={docData} />
            </div>
          </div>

          <div className="DoctorView_drop_down">
            <div
              className="DoctorView_drop_down_header"
              onClick={() => {
                if (dropDown === 3) {
                  setDtopDown(null);
                } else {
                  setDtopDown(3);
                }
              }}
            >
              <div className="DoctorView_drop_down_header_lil_groupe">
                <img src={answer} className="" alt="answer" />
                <p className="DoctorView_drop_down_text">Langues parlées</p>
              </div>
              <IoIosArrowUp
                size={20}
                color="#284358"
                style={{
                  transition: "all .2s",
                  transform: `rotate(${dropDown === 3 ? "180deg" : "0deg"})`,
                }}
              />
            </div>
            <div
              className="dropDown"
              ref={spokenLanguagesRef}
              style={
                dropDown === 3
                  ? {
                      height: spokenLanguagesRef?.current?.scrollHeight + "px",
                    }
                  : {
                      height: "0px",
                    }
              }
            >
              {" "}
              <SpokenLanguages
                spokenLanguages={docData?.spokenLanguages ?? []}
              />
            </div>
          </div>
          <div className="DoctorView_drop_down">
            <div
              className="DoctorView_drop_down_header"
              onClick={() => {
                if (dropDown === 4) {
                  setDtopDown(null);
                } else {
                  setDtopDown(4);
                }
              }}
            >
              <div className="DoctorView_drop_down_header_lil_groupe">
                <img
                  src={experimentResults}
                  className=""
                  alt="experimentResults"
                />
                <p className="DoctorView_drop_down_text">Soins et actes</p>
              </div>
              <IoIosArrowUp
                size={20}
                color="#284358"
                style={{
                  transition: "all .2s",
                  transform: `rotate(${dropDown === 4 ? "180deg" : "0deg"})`,
                }}
              />
            </div>
            <div
              className="dropDown"
              ref={careAndProceduresRef}
              style={
                dropDown === 4
                  ? {
                      height:
                        careAndProceduresRef?.current?.scrollHeight + "px",
                    }
                  : {
                      height: "0px",
                    }
              }
            >
              <CareAndProcedures careServices={docData?.careServices ?? []} />
            </div>
          </div>
          <div className="DoctorView_drop_down">
            <div
              className="DoctorView_drop_down_header"
              onClick={() => {
                if (dropDown === 5) {
                  setDtopDown(null);
                } else {
                  setDtopDown(5);
                }
              }}
            >
              <div className="DoctorView_drop_down_header_lil_groupe">
                <img src={cash} className="" alt="cash" />
                <p className="DoctorView_drop_down_text">
                  Moyens de paiement acceptés
                </p>
              </div>
              <IoIosArrowUp
                size={20}
                color="#284358"
                style={{
                  transition: "all .2s",
                  transform: `rotate(${dropDown === 5 ? "180deg" : "0deg"})`,
                }}
              />
            </div>
            <div
              className="dropDown"
              ref={paymentMethodsAcceptedRef}
              style={
                dropDown === 5
                  ? {
                      height:
                        paymentMethodsAcceptedRef?.current?.scrollHeight + "px",
                    }
                  : {
                      height: "0px",
                    }
              }
            >
              {" "}
              <PaymentMethodsAccepted
                acceptedPaymentMethod={docData?.acceptedPaymentMethod ?? []}
              />{" "}
            </div>
          </div>
        </div>
      )}
      {activeLink === 1 && (
        <LocationCentent
          healthCareEstablishment={docData?.healthCareEstablishmentData}
          address={docData?.address}
          PatientAction={PatientAction}
        />
      )}
      {activeLink === 2 && (
        <WorkTimetable
          openingHours={docData?.openingHours}
          phone={docData?.phone}
          paramsId={docData?.id}
          PatientAction={PatientAction}
        />
      )}
      {/* {activeLink === 3 && <Tariff healthCareEstablishment={docData?.healthCareEstablishment} />} */}
      {activeLink === 4 && (
        <DoctorsList
          specialities={docData?.specialities}
          doctors={docData?.doctors}
        />
      )}
    </div>
  );
};

export const PresentationOfTheFirm = (props) => {
  const convertCommentFromJSONToHTML = (text) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(
      stateToHTML(convertFromRaw(JSON.parse(text))),
      "text/html"
    ).innerHTML;
    return stateToHTML(convertFromRaw(JSON.parse(text)));
  };
  return (
    <div>
      <p className="PresentationOfTheFirm_text">
        {props?.docData?.healthCareEstablishmentData?.presentation
          ? parse(
              `${convertCommentFromJSONToHTML(
                props?.docData?.healthCareEstablishmentData?.presentation
              )}`
            )
          : ""}
      </p>
      <div className="PresentationOfTheFirm_image_container">
        {props?.docData?.images?.lenght !== 0 &&
          props?.docData?.images?.map((item) => {
            return (
              <img
                src={item?.imagePath}
                className="PresentationOfTheFirm_image"
                alt={item?.name}
              />
            );
          })}
      </div>
    </div>
  );
};

export const SpokenLanguages = (props) => {
  return (
    <div>
      {props.spokenLanguages?.lenght !== 0 &&
        props.spokenLanguages.map((item) => {
          return (
            <div className="SpokenLanguages">
              <img
                src={
                  item?.name === "Arabe"
                    ? ar_image
                    : item?.name === "Allemand"
                    ? Allemand
                    : item?.name === "Russe"
                    ? Russe
                    : item?.name === "Français"
                    ? fr_image
                    : item?.name === "Anglais"
                    ? AG_image
                    : item?.name === "Espagnol"
                    ? spainFlag
                    : item?.name === "Italien"
                    ? ItalyFlag
                    : item?.name === "Ukrainien"
                    ? Ukrainien
                    : item?.name === "Portugais"
                    ? Portugais
                    : item?.name === "Chinois"
                    ? Chinois
                    : item?.name === "Japonais"
                    ? Japonais
                    : item?.name === "Turc"
                    ? Turc
                    : item?.name === "Wolof (Sénégal)"
                    ? Wolof
                    : item?.name === "Amazigh"
                    ? Amazigh
                    : item?.name === "Rifain"
                    ? Rifain
                    : ""
                }
                className="SpokenLanguages_image"
                alt="fr_image"
              />
              <p className="Training_content">{item?.name}</p>
            </div>
          );
        })}
    </div>
  );
};

export const CareAndProcedures = (props) => {
  return (
    <div className="CareAndProcedures_container">
      {props?.careServices.lenght !== 0 &&
        props?.careServices.map((item, key) => {
          return (
            <button className="CareAndProcedures_button" key={key}>
              {item?.label ?? ""}
            </button>
          );
        })}
    </div>
  );
};

export const PaymentMethodsAccepted = (props) => {
  const acceptedPaymentMethod = props?.acceptedPaymentMethod;
  return (
    <div>
      {acceptedPaymentMethod.lenght !== 0 &&
        acceptedPaymentMethod.map((item, key) => {
          return (
            <div className="PaymentMethodsAccepted" key={key}>
              <img
                src={
                  item?.name === "debit_card"
                    ? Cart_bank
                    : item?.name === "cash"
                    ? money
                    : item?.name === "check"
                    ? check
                    : item?.name === "other"
                    ? autres_icon
                    : item?.name === "credit"
                    ? cheque_icon
                    : item?.name === "wire_transfer"
                    ? wire_transfer
                    : item?.name === "bill_of_exchange"
                    ? money_bag_icon
                    : ""
                }
                className=""
                alt="PaymentMethodsAccepted"
              />
              <p className="PaymentMethodsAccepted_text">
                {item?.name === "cash"
                  ? "Espèces"
                  : item?.name === "check"
                  ? "Chèque"
                  : item?.name === "debit_card"
                  ? "Carte Bancaire"
                  : item?.name === "bill_of_exchange"
                  ? "Lettre de change"
                  : item?.name === "credit"
                  ? "Credit"
                  : item?.name === "wire_transfer"
                  ? "Virement bancaire"
                  : item?.name === "other"
                  ? "Autre"
                  : ""}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export const LocationCentent = (props) => {
  const healthCareEstablishmentData = props?.healthCareEstablishment;
  let positionNet = [];
  const position = healthCareEstablishmentData?.gpsLocation?.split(" ");
  if (healthCareEstablishmentData?.gpsLocation) {
    positionNet = position.map((element) => {
      return parseFloat(element);
    });
  }

  const [center, setCenter] = useState({ positionNet });
  const [zoom, setZoom] = useState(11);
  const AnyReactComponent = () => (
    <ImLocation className="FcPlus_maps_icon" size={40} color="#00B2D8" />
  );
  return (
    <div className="LocationCentent" style={{ position: "relative" }}>
      <GoogleMapReact
        onClick={() => props.PatientAction("itinerary")}
        style={{ height: "200px" }}
        bootstrapURLKeys={{ key: "AIzaSyBS91EliZuReTNQNLYV7o2i7LaLzcv5CG4" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent
          lat={positionNet[0]}
          lng={positionNet[1]}
          text="My Marker"
        />
      </GoogleMapReact>
      <div style={{ paddingTop: "1.5rem" }}>
        <p className="locationCentent_title">Cabinet</p>
        <p className="locationCentent_text">{props?.address}</p>
      </div>
      {/* <div style={{ paddingTop: '1.5rem' }} >
            <p className="locationCentent_title">Informations pratiques</p>
            <p className="locationCentent_text">Rez-de-chaussée<br />
                Accès handicapé<br />
                Parking gratuit</p>
        </div> */}
    </div>
  );
};

export const WorkTimetable = (props) => {
  const openingHoursOfnull = [
    {
      id: 1,
      dayOfWeek: "Lundi",
      startOne: "09:00",
      endOne: "12:30",
      startTwo: "15:00",
      endTwo: "19:30",
    },
    {
      id: 2,
      dayOfWeek: "Mardi",
      startOne: "09:00",
      endOne: "12:30",
      startTwo: "15:00",
      endTwo: "19:30",
    },
    {
      id: 3,
      dayOfWeek: "Mercredi",
      startOne: "09:00",
      endOne: "12:30",
      startTwo: "15:00",
      endTwo: "19:30",
    },
    {
      id: 4,
      dayOfWeek: "Jeudi",
      startOne: "09:00",
      endOne: "12:30",
      startTwo: "15:00",
      endTwo: "19:30",
    },
    {
      id: 5,
      dayOfWeek: "Vendredi",
      startOne: "09:00",
      endOne: "12:30",
      startTwo: "15:00",
      endTwo: "19:30",
    },
    { id: 6, dayOfWeek: "Samedi", startOne: "09:00", endOne: "13:00" },
  ];

  const [showNumber, setShowNumber] = useState(true);

  return (
    <div className="WorkTimetable_big_container">
      <div className="WorkTimetable_container" style={{ marginTop: "10px" }}>
        <p className="WorkTimetable_title">Ouverture du secrétariat</p>
        {
          <OpeningHours
            openingHours={props?.openingHours ?? openingHoursOfnull}
          />
        }
      </div>
      <div className="WorkTimetable_container">
        <p className="WorkTimetable_title">Contact du secrétariat</p>
        {showNumber ? (
          <p className="WorkTimetable_phoneNumber">
            {props?.phone?.substring(0, 5)}...{" "}
            <span
              onClick={() => {
                props.PatientAction("callclick");
                setShowNumber(false);
              }}
            >
              Afficher le numéro de téléphone
            </span>
          </p>
        ) : (
          <p className="WorkTimetable_phoneNumber">{props?.phone}</p>
        )}
      </div>
      <div className="WorkTimetable_container">
        <p className="WorkTimetable_title">Contact d’urgence</p>
        <p className="WorkTimetable_content">
          En cas d’urgence contactez le 141
        </p>
      </div>
    </div>
  );
};

export const DoctorsList = (props) => {
  const history = useHistory();
  const [SpeciatyId, setSpeciatyId] = useState(-1);
  const specialities = props?.specialities ?? [];
  const doctors = props?.doctors ?? [];

  return (
    <>
      <div className="specialy_list">
        <div
          className={
            -1 === SpeciatyId ? "specialy_name_active" : "specialy_name"
          }
          onClick={() => {
            setSpeciatyId(-1);
          }}
        >
          <p>Toutes</p>
        </div>
        {specialities.map((item, key) => {
          return (
            <div
              className={
                item?.id === SpeciatyId
                  ? "specialy_name_active"
                  : "specialy_name"
              }
              onClick={() => {
                setSpeciatyId(item?.id);
              }}
              key={key}
            >
              <p>{item?.label}</p>
            </div>
          );
        })}
      </div>
      {doctors.map((item, key) => {
        if (item?.speciality?.id === SpeciatyId) {
          return (
            <div
              className="DoctorsList_container"
              onClick={() => history.push(`/doctor/details/${item?.slug}`)}
              key={key}
            >
              <div
                className="DoctorsOfficeSearchResult_container"
                style={{ marginBottom: "0px" }}
              >
                <img src={icon_user} alt="icon_user" />
                <div className="DoctorsOffice_content">
                  <p className="doctors_specialty">
                    Dr {(item?.firstName ?? "") + " " + (item?.lastName ?? "")}
                  </p>
                  <p className="doctors_name">
                    {item?.speciality?.label ?? ""}
                  </p>
                </div>
              </div>
              <IoIosArrowForward size={20} color="#284358" />
            </div>
          );
        } else if (-1 === SpeciatyId) {
          return (
            <div
              className="DoctorsList_container"
              onClick={() => history.push(`/doctor/details/${item?.slug}`)}
              key={key}
            >
              <div
                className="DoctorsOfficeSearchResult_container"
                style={{ marginBottom: "0px" }}
              >
                <img src={icon_user} alt="icon_user" />
                <div className="DoctorsOffice_content">
                  <p className="doctors_specialty">
                    Dr {(item?.firstName ?? "") + " " + (item?.lastName ?? "")}
                  </p>
                  <p className="doctors_name">
                    {item?.speciality?.label ?? ""}
                  </p>
                </div>
              </div>
              <IoIosArrowForward size={20} color="#284358" />
            </div>
          );
        }
      })}
    </>
  );
};

// export const Tariff = (props) => {
//     return (<div className='WorkTimetable_big_container'>
//         <div className='WorkTimetable_container' style={{ marginTop: "10px" }}>
//             <p className="PresentationOfTheFirm_text">Dr. Amine vous reçoit à son cabinet au sein de la maison.
//                 Préserver la santé, de maintenir ou d’améliorer la condition physique des sportifs de tous âges et de tous niveaux.
//                 Il reçoit les enfants et les adultes pour tous types de soins médicaux généraux (consultation, contrôle annuel,
//                 vaccination, bilan de santé). Il traite également les maladies infectieuses et assure le suivi des nourrissons
//                 (pesée, mesure, vaccination).</p>
//         </div>
//         <div className='WorkTimetable_container tariff_display'>
//             <p className="tariff_display_what">Consultation</p>
//             <p className="tariff_display_number">200.00 Dhs</p>
//         </div>
//         <div className='WorkTimetable_container tariff_display'>
//             <p className="tariff_display_what">Echo-cardiographie</p>
//             <p className="tariff_display_number">400.00 Dhs</p>
//         </div>
//         <div className='WorkTimetable_container tariff_display'>
//             <p className="tariff_display_what">Scanner</p>
//             <p className="tariff_display_number">400.00 Dhs</p>
//         </div>
//     </div>)
// }

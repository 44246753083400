import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "search",
});

class SearchService {
  constructor() {}

  getSearchedData(search, city, page, limit) {
    let params = {
      search: search,
      city: city,
      page: page,
      limit: limit,
    };
    return authAxios.get(``, { params, withCredentials: true });
  }
}

export default new SearchService();

import React from "react";
import Header from "../../helpers/Header/Header";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ImPlay2 } from "react-icons/im";
import howItWorksOne from "../../images/howItWorksOne.png";
import howItWorksOne2 from "../../images/howItWorksOne2.png";
import howItWorksOne3 from "../../images/howItWorksOne3.png";
import { Carousel } from "react-responsive-carousel";
import Docteur from "../../images/Docteur.png";
import Footer from "../../helpers/Footer/Footer";
import { useHistory } from "react-router-dom";
import StickyFooter from "../../helpers/StickyFooter/StickyFooter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./LandingPage.css";

function LandingPage() {
  const history = useHistory();
  return (
    <>
      <div>
        <Header />
        <div className="landing_page_search_section">
          <p className="landing_page_search_section_title">
            Prenez rendez-vous en ligne chez un professionnel de santé
          </p>
          <p className="landing_page_search_section_paragraphe">
            C’est immédiat, simple et gratuit.
          </p>
          <div
            className="search_input_container"
            onClick={() => history.push("/search")}
          >
            <input
              type="text"
              className="landing_page_search_section_input"
              placeholder="Médecin, établissement ou spécialité…"
            />
            <button className="landing_page_search_section_button">
              <BiSearchAlt2
                size={25}
                className="landing_page_search_section_button_icon"
              />
            </button>
          </div>
        </div>
        <div className="landing_page_show_it_works_section">
          <p className="landing_page_show_it_works_section_title">
            Comment ça marche ?
          </p>
          <div className="landing_page_show_it_works_section_one">
            <img src={howItWorksOne} className="" />
            <p className="landing_page_show_it_works_section_one_paragraphe">
              Trouvez
              <br /> un médecin proche
            </p>
            <MdKeyboardArrowDown size={35} />
          </div>
          <div className="landing_page_show_it_works_section_one">
            <img src={howItWorksOne2} className="" />
            <p className="landing_page_show_it_works_section_one_paragraphe">
              Prenez rendez-vous
              <br /> selon vos disponibilités
            </p>
            <MdKeyboardArrowDown size={35} />
          </div>
          <div className="landing_page_show_it_works_section_one">
            <img src={howItWorksOne3} className="" />
            <p className="landing_page_show_it_works_section_one_paragraphe">
              Recevez
              <br /> un rappel 24h avant
            </p>
          </div>
          <div className="landing_page_show_it_works_section_two">
            <p className="landing_page_show_it_works_section_two_paragraphe">
              Regarder la
              <br /> présentation en vidéo
            </p>
            <ImPlay2 size={40} color="#fff" />
          </div>
        </div>
        <div className="landing_page_feed_backs_section">
          <p className="landing_page_feed_backs_section_paragraphe">
            Ce que nos utilisateurs disent
            <br /> à propos de nous
          </p>

          <Carousel>
            <div className="carousel_container">
              <p className="carousel_container_paragraphe">
                Très satisfaite. Médecin a l’écoute et il prend
                <br /> le temps nécessaire pour chaque patient.
              </p>
              <p className="carousel_container_paragraphe_title">’ ’</p>
              <p className="carousel_container_paragraphe_name">
                Par <span>Amina F</span>
              </p>
            </div>
            <div className="carousel_container">
              <p className="carousel_container_paragraphe">
                Très satisfaite. Médecin a l’écoute et il prend
                <br /> le temps nécessaire pour chaque patient.
              </p>
              <p className="carousel_container_paragraphe_title">’ ’</p>
              <p className="carousel_container_paragraphe_name">
                Par <span>Amina F</span>
              </p>
            </div>
            <div className="carousel_container">
              <p className="carousel_container_paragraphe">
                Très satisfaite. Médecin a l’écoute et il prend
                <br /> le temps nécessaire pour chaque patient.
              </p>
              <p className="carousel_container_paragraphe_title">’ ’</p>
              <p className="carousel_container_paragraphe_name">
                Par <span>Amina F</span>
              </p>
            </div>
          </Carousel>
        </div>
        <div className="landing_page_pro_section">
          <img src={Docteur} className="" />
          <p className="landing_page_pro_section_title">
            Vous êtes
            <br /> un professionnel de santé ?
          </p>
          <p className="landing_page_pro_section_paaragraphe">
            Profitez des 20 premiers
            <br /> jours gratuitement
          </p>
          <button className="landing_page_pro_section_button">
            Profitez de cette offre dés maintenant
          </button>
        </div>
        <Footer />
      </div>
      <StickyFooter />
    </>
  );
}

export default LandingPage;

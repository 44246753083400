import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import NoReasult from "../../images/NoReasult.png";
import citiesService from "../../services/cities.service";
import "../SearchPage/SearchPage.css";

export const SearchPageSpecialy = () => {
  const history = useHistory();
  const [city, setCity] = useState([]);
  const [search, setSearch] = useState(null);
  const [specialities, seSpecialities] = useState("");

  useEffect(() => {
    getCities();
  }, [search]);

  const getCities = async () => {
    try {
      let response = await citiesService.getCities();
      setCity(response?.data?.cities ?? []);
      console.log(city);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="SearchPage">
        <div className="SearchPage_reach_input_container">
          <MdKeyboardArrowLeft
            size={30}
            color="#284358"
            onClick={() => {
              history.goBack();
            }}
          />
          <input
            value={search}
            placeholder="Médecin, établissement ou spécialité…"
            className="search_input"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div className="search_clear_icon_container">
            <IoMdClose
              size={20}
              color="#284358"
              onClick={() => setSearch("")}
            />
          </div>
        </div>
        <div className="search_result">
          {/*no results found start*/}
          {city.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80%",
              }}
            >
              <NoResultSearch />
            </div>
          )}
          {/*no results found end*/}

          {city.length !== 0 && (
            <div>
              {city.map((item) => {
                return <SearchSuggestionsText data={item} />;
              })}
            </div>
          )}

          {/* location button start */}
          {/* <div>
                        <LocalisationButton />
                    </div> */}
          {/* location button end */}
        </div>
      </div>
    </>
  );
};

export const NoResultSearch = () => {
  return (
    <div className="NoResultSearch">
      <img src={NoReasult} />
      <p className="NoResultSearch_paragraphe">
        Trouvez un médecin, un
        <br /> établissement ou une spécialité
      </p>
    </div>
  );
};

export const SearchSuggestionsText = (props) => {
  const history = useHistory();
  const params = useParams();
  console.log("params?.specialy", params?.specialy);
  return (
    <div
      className="SearchSuggestionsText_container"
      onClick={() =>
        history.push(
          `/search/results?searched=${params?.specialy},${props.data?.label}`
        )
      }
    >
      <p className="SearchSuggestionsText">
        <strong></strong>
        {props.data?.label ?? ""}
      </p>
    </div>
  );
};

import { createStore, combineReducers } from "redux";
import currentHealthCareEstablishment from "./reducers/currentHealthCareEstablishment";
import currentPathReducer from "./reducers/currentPathReducer";
import userData from "./reducers/userData";

export default createStore(
  combineReducers({
    Auth: currentPathReducer,
    userData: userData,
    currentHealthCareEstablishment: currentHealthCareEstablishment,
  })
);

import React, { Component } from "react";
import { AiOutlineCloseCircle, AiOutlineWarning } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import ee from "event-emitter";
import "./Notifications.css";

const emitter = new ee();

export const notify = ({ msg, type }) => {
  emitter.emit("notification", { msg, type });
};

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      display: "none",
      message: { msg: "", type: "" },
    };
    this.timeout = null;
    emitter.on("notification", ({ msg, type }) => {
      this.onShow({ msg, type });
    });
  }
  componentDidMount() {
    console.log("got heres");
  }

  onShow({ msg, type }) {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.setState({ height: 0, display: "none" }, () => {
        this.timeout = setTimeout(() => {
          this.showNotification({ msg, type });
        }, 300);
      });
    } else {
      this.showNotification({ msg, type });
    }
  }

  showNotification = ({ msg, type }) => {
    this.setState(
      {
        display: "flex",
        message: { msg: typeof msg === "string" && msg, type },
      },
      () => {
        this.timeout = setTimeout(() => {
          this.setState({ height: 0, display: "none" });
        }, 5000);
      }
    );
  };

  renderMessageStyle() {
    const { message, display } = this.state;
    if (message.type === "danger") {
      return (
        <div
          className="ProductPage_carousel_product_added_notification_danger"
          style={{ display: display }}
        >
          <AiOutlineCloseCircle className="ProductPage_carousel_product_added_notification_danger_icon" />
          <p className="ProductPage_carousel_product_added_notification_text">
            {message.msg}
          </p>
          <IoIosClose
            className="ProductPage_carousel_product_added_notification_icon_close"
            onClick={() => this.setState({ height: 0, display: "none" })}
          />
        </div>
      );
    } else if (message.type === "success") {
      return (
        <div
          className="ProductPage_carousel_product_added_notification"
          style={{ display: display }}
        >
          <BsCheck className="ProductPage_carousel_product_added_notification_icon" />
          <p className="ProductPage_carousel_product_added_notification_text">
            {message.msg}
          </p>
          <IoIosClose
            className="ProductPage_carousel_product_added_notification_icon_close"
            onClick={() => this.setState({ height: 0, display: "none" })}
          />
        </div>
      );
    } else if (message.type === "warning") {
      return (
        <div
          className="ProductPage_carousel_product_added_notification_warning"
          style={{ display: display }}
        >
          <AiOutlineWarning className="ProductPage_carousel_product_added_notification_warning_icon" />
          <p className="ProductPage_carousel_product_added_notification_text">
            {message.msg}
          </p>
          <IoIosClose
            className="ProductPage_carousel_product_added_notification_icon_close"
            onClick={() => this.setState({ height: 0, display: "none" })}
          />
        </div>
      );
    }
  }
  render() {
    const { height } = this.state;
    return (
      <div className="FlashMessage__container" style={{ height: height }}>
        {this.renderMessageStyle()}
      </div>
    );
  }
}

export default Notifications;

import React, { useEffect, useState } from "react";
import ProtectedRoute from "./protected-route";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LandingPage from './pages/LandingPage/LandingPage';
import SearchPage from "./pages/SearchPage/SearchPage";
import SearchResultsPage from "./pages/SearchResultsPage/SearchResultsPage";
import DoctorView from "./pages/DoctorView/DoctorView";
import { UserProfile } from "./pages/UserProfile/UserProfile";
import { UserProfileIfo } from "./pages/UserProfileIfo/UserProfileIfo";
import { UserProfileFavorites } from "./pages/UserProfileFavorites/UserProfileFavorites";
import { UserProfileConsulted } from "./pages/UserProfileConsulted/UserProfileConsulted";
import { ErrorPage } from "./pages/ErrorPage/ErrorPage";
import { SearchPageSpecialy } from "./pages/SearchPageSpecialy/SearchPageSpecialy";
import SuccessComponent from "./helpers/AuthComponent/SuccessComponent";
import failureComponent from "./helpers/AuthComponent/failureComponent";
import { EstablishmentView } from "./pages/EstablishmentView/EstablishmentView";
import DataInMount from "./helpers/DataInMount";
import './App.css';
import Notifications from "./helpers/Notifications/Notifications";

const App = () => {
  useEffect(() => {
    DataInMount.getAuthenticatedUser()
  }, []);

  return (
    <>

      <Notifications />
      <BrowserRouter>
        <Switch>
          <div className="App">
            <Route path="/" exact component={LandingPage} />
            <Route path="/search" exact component={SearchPage} />
            <Route path="/search/city/:specialy" exact component={SearchPageSpecialy} />
            <Route path="/search/results" exact component={SearchResultsPage} />

            <Route path="/establishment/details/:slug" exact component={EstablishmentView} />
            <Route path="/doctor/details/:slug" exact component={DoctorView} />

            <ProtectedRoute path="/profile" exact component={UserProfile} />
            <ProtectedRoute path="/profile/info" exact component={UserProfileIfo} />
            <ProtectedRoute path="/profile/favorites" exact component={UserProfileFavorites} />
            <ProtectedRoute path="/profile/consulted" exact component={UserProfileConsulted} />

            {/* <ProtectedRoute path="/profile/consulted" exact component={UserProfileConsulted} /> */}

            <Route path='/error' exact component={ErrorPage} />

            <Route path='/auth/success' exact component={SuccessComponent} />
            <Route path='/auth/failed' exact component={failureComponent} />
          </div>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import NoReasult from '../../images/NoReasult.png'
import icon_user from '../../images/icon_user.png'
import { IoLocationOutline } from "react-icons/io5";
import { BiCurrentLocation } from "react-icons/bi";
import searchService from '../../services/search.service';
import specialityService from '../../services/speciality.service';
import Highlighter from 'react-highlight-words';
import StickyFooter from '../../helpers/StickyFooter/StickyFooter';
import './SearchPage.css';

const SearchPage = () => {
    const history = useHistory();
    const [search, setSearch] = useState(null);
    const [data, seData] = useState([]);
    const [specialities, seSpecialities] = useState([]);

    useEffect(() => {
        getSearchedData()
        getSpecialities()
    }, [search])

    const getSearchedData = async () => {
        try {
            let response = await searchService.getSearchedData(search)
            seData(response?.data?.data ?? [])
        } catch (error) {
            console.log(error);
        }
    }

    const getSpecialities = async () => {
        try {
            let response = await specialityService.getSpecialities(search)
            seSpecialities(response?.data?.specialities ?? [])
            console.log(specialities);
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <div className='SearchPage'>
                <div className='SearchPage_reach_input_container'>
                    <MdKeyboardArrowLeft size={30} color="#284358" onClick={() => {
                        history.push('/')
                    }} />
                    <input value={search} placeholder='Médecin, établissement ou spécialité…' className='search_input' onChange={(e) => {
                        setSearch(e.target.value)
                    }} />
                    <div className='search_clear_icon_container' >
                        <IoMdClose size={20} color="#284358" onClick={() => setSearch("")} />
                    </div>
                </div>
                <div className='search_result'>
                    {/*no results found start*/}
                    {(data.length === 0 && specialities.length === 0) && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "80%" }}>
                        <NoResultSearch />
                    </div>}
                    {/*no results found end*/}

                    {specialities.length !== 0 && <div>
                        {
                            specialities.map((item) => {
                                return (<SearchSuggestionsText data={item} search={search} />)
                            })
                        }
                    </div>}
                    {/*no Doc's Office start*/}
                    {data.length !== 0 && <div>
                        {
                            data.map((item) => {
                                return (<DoctorsOffice data={item} />)
                            })
                        }
                    </div>}
                    {/*no Doc's Office end*/}

                    {/* location button start */}
                    {/* <div>
                            <LocalisationButton />
                        </div> */}
                    {/* location button end */}
                </div>
            </div>
            <StickyFooter />
        </>
    );
}

export default SearchPage;


export const NoResultSearch = () => {
    return (<div className="NoResultSearch">
        <img src={NoReasult} />
        <p className='NoResultSearch_paragraphe'>Trouvez un médecin, un<br /> établissement ou une spécialité</p>
    </div>)
}

export const DoctorsOffice = (props) => {
    const history = useHistory();
    return (<div className='DoctorsOffice' onClick={() => {
        if (props.data?.dataType === 'doctor') {
            history.push(`/doctor/details/${props?.data?.slug}`)
        } else {
            history.push(`/establishment/details/${props?.data?.slug}`)
        }
    }}>

        {
            props.data?.dataType === 'doctor' ?
                (props?.data?.doctorData?.picture ? <div className='DoctorView_header_image_container' style={{ width: '4rem', height: '4rem', margin: '0px' }}>
                    <img src={props?.data?.doctorData?.picture} alt='doc' />
                </div> : <img src={icon_user} alt='doc' />) : (props?.data?.images.length !== 0 ? <div className='DoctorView_header_image_container' style={{ width: '4rem', height: '4rem', margin: '0px' }}>
                    <img src={props?.data?.images[0]?.imagePath} alt='doc' />
                </div> : <img src={icon_user} alt='doc' />)
        }

        <div className='DoctorsOffice_content'>
            {props.data?.dataType !== "healthCareEstablishment" ?
                <p className='doctors_name'>Dr {(props.data?.firstName ?? '') + ' ' + (props.data?.lastName ?? '')}</p> :
                <p className='doctors_name'>{props.data?.name ?? ""}</p>}
            <p className='doctors_specialty'>{props.data?.speciality?.label ?? ''}</p>
            {props?.data?.address ? <p className='doctors_address'><IoLocationOutline size={15} />{props?.data?.address}</p> : ''}
        </div>
    </div>)
}

export const LocalisationButton = (props) => {
    return (<div>
        <button className='LocalisationButton'><BiCurrentLocation size={20} color='#00b2d8' /> Autour de moi</button>
    </div>)
}

export const SearchSuggestionsText = (props) => {
    const history = useHistory();
    return (<div className="SearchSuggestionsText_container" onClick={() => history.push(`/search/city/${props.data?.label}`)}>
        <p className="SearchSuggestionsText">
            <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[props.search]}
                autoEscape={true}
                textToHighlight={props.data?.label ?? ''}
            /></p>
    </div>)
}
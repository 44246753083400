import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import magnifying from "../../images/magnifying.png";
import icon_user from "../../images/icon_user.png";
import { useHistory } from "react-router-dom";
import StickyFooter from "../../helpers/StickyFooter/StickyFooter";
import searchService from "../../services/search.service";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import "./SearchResultsPage.css";

const SearchResultsPage = () => {
  const [searched, setSearched] = useState("");
  const [city, setCity] = useState("");
  const [data, seData] = useState([]);
  const [page, sePage] = useState(1);
  const [limit, seLimit] = useState(3);
  const [loader, seLoader] = useState(false);
  const [hasMore, sethasMore] = useState(true);

  useEffect(() => {
    getSearchedData();
  });

  // const getParamsFromURL = () => {
  //     const params = new URL(window.location.href).searchParams.get("searched");
  //     const paramsArray = params?.split(',')
  //     if (paramsArray) {
  //         setSearched(paramsArray[0])
  //         setCity(paramsArray[1])
  //     }
  // }

  const getSearchedData = async () => {
    const params = new URL(window.location.href).searchParams.get("searched");
    const paramsArray = params?.split(",");
    setSearched(paramsArray[0]);
    setCity(paramsArray[1]);
    try {
      let response = await searchService.getSearchedData(
        paramsArray[0],
        paramsArray[1],
        page,
        limit
      );
      seData(response?.data?.data ?? []);
      seLoader(true);
      if (response?.data?.data?.length === response?.data?.totalItems) {
        sethasMore(false);
      } else {
        sethasMore(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = () => {
    // setTimeout(() => {
    //     seData([...data])
    //     seLimit(seLimit + 1)
    //     getSearchedData()
    // }, 1000);
  };

  return (
    <>
      <div className="SearchResultsPage">
        <div className="search_result_header_container">
          <div className="search_result_header">
            <BiSearch size={20} color="#ffffff50" />
            <p className="search_result_header_paragraphe">{searched}</p>
          </div>
          <div className="search_result_header_copy">
            <IoLocationOutline size={20} color="#ffffff50" />
            <p className="search_result_header_paragraphe_light">{city}</p>
          </div>
        </div>
        <div>
          <FilterAndResult data={data} />
        </div>
        {data.length === 0 && loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <NoResultSearch />
          </div>
        ) : (
          <InfiniteScroll
            style={{ overflow: "none" }}
            dataLength={data.length}
            next={fetchData()}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget={"html"}
          >
            {data.length !== 0 && (
              <div>
                {data.map((item) => {
                  return <DoctorsOfficeSearchResult data={item} />;
                })}
              </div>
            )}
          </InfiniteScroll>
        )}

        {/*no results found end*/}

        {/* {data.length !== 0 && <div>
                    {
                        data.map((item) => {
                            return (<DoctorsOfficeSearchResult data={item} />)
                        })
                    }
                </div>} */}
      </div>
      <StickyFooter />
    </>
  );
};

export default SearchResultsPage;

export const NoResultSearch = () => {
  return (
    <div className="NoResultSearch">
      <img src={magnifying} />
      <p className="NoResultSearch_paragraphe_strong">Aucun résultat trouvé</p>
      <p className="NoResultSearch_paragraphe_light">
        Revérifez le nom de recherchée ou
        <br />
        cherchez par ville et par secteur
      </p>
    </div>
  );
};

export const FilterAndResult = (props) => {
  return (
    <div className="FilterAndResult_container">
      <div className="FilterAndResult">
        <p className="FilterAndResult_number_paragraphe">
          <strong>{props.data.length}</strong>résultats trouvés
        </p>
      </div>
      {/* <div className='FilterAndResult'>
            <div className='FilterAndResult_icon_container'>
                <RiSoundModuleFill size={20} color='#00B2D8' />
            </div>
            <p className="FilterAndResult_filter_title">Filter</p>
        </div> */}
    </div>
  );
};

export const DoctorsOfficeSearchResult = (props) => {
  const history = useHistory();
  return (
    <div className="DoctorsOfficeSearchResult">
      <div className="DoctorsOfficeSearchResult_container">
        {props.data?.dataType === "doctor" ? (
          props?.data?.doctorData?.picture ? (
            <div
              className="DoctorView_header_image_container"
              style={{ width: "4rem", height: "4rem", margin: "0px" }}
            >
              <img src={props?.data?.doctorData?.picture} alt="doc" />
            </div>
          ) : (
            <img src={icon_user} alt="doc" />
          )
        ) : props?.data?.images.length !== 0 ? (
          <div
            className="DoctorView_header_image_container"
            style={{ width: "4rem", height: "4rem", margin: "0px" }}
          >
            <img src={props?.data?.images[0]?.imagePath} alt="doc" />
          </div>
        ) : (
          <img src={icon_user} alt="doc" />
        )}
        <div className="DoctorsOffice_content">
          {props.data?.dataType !== "healthCareEstablishment" ? (
            <p className="doctors_name">
              Dr{" "}
              {(props.data?.firstName ?? "") +
                " " +
                (props.data?.lastName ?? "")}
            </p>
          ) : (
            <p className="doctors_name">{props.data?.name ?? ""}</p>
          )}
          <p className="doctors_specialty">
            {props.data?.speciality?.label ?? ""}
          </p>
          {props?.data?.address ? (
            <p className="doctors_address">
              <IoLocationOutline size={15} />
              {props?.data?.address}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        {/* <button className='DoctorsOfficeSearchResult_button'><GoCalendar size={20} color='#00b2d8' />Prendre un rendez-vous</button> */}
        <button
          className="DoctorsOfficeSearchResult_button"
          onClick={() => {
            if (props.data?.dataType === "doctor") {
              history.push(`/doctor/details/${props?.data?.slug}`);
            } else {
              history.push(`/establishment/details/${props?.data?.slug}`);
            }
          }}
        >
          Plus de détails
        </button>
      </div>
    </div>
  );
};

export const PostsLoader = (props) => {
  return (
    <div className="DoctorsOfficeSearchResult">
      <ContentLoader
        speed={2}
        width={400}
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#ffffff"
        foregroundColor="#b0b0b0"
        {...props}
      >
        <rect x="73" y="11" rx="3" ry="3" width="320" height="7" />
        <rect x="76" y="31" rx="3" ry="3" width="314" height="7" />
        <circle cx="35" cy="31" r="28" />
        <rect x="16" y="74" rx="0" ry="0" width="377" height="42" />
      </ContentLoader>
    </div>
  );
};

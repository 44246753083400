import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "patients",
});

class PatientService {
  constructor() {}

  createPatient() {
    return authAxios.post(``, null, { withCredentials: true });
  }

  updatePatient(data) {
    return authAxios.patch(``, data, { withCredentials: true });
  }

  PatientBookmark(data) {
    return authAxios.post(`/bookmarks`, data, { withCredentials: true });
  }

  PatientAction(data) {
    return authAxios.post(`/actions`, data, { withCredentials: true });
  }
}

export default new PatientService();

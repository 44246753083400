import React from "react";
import Header from "../../helpers/Header/Header";
import errorImg from "../../images/error-imagz.png";
import { useHistory } from "react-router-dom";
import "./ErrorPage.css";

export const ErrorPage = () => {
  const history = useHistory();
  return (
    <>
      <Header />
      <div className="ErrorPage_container">
        <img src={errorImg} />
        <p className="ErrorPage_title">Erreur 404</p>
        <p className="ErrorPage_paragraphe">
          Cette page que vous
          <br />
          recherchez n’existe pas
        </p>
        <button className="ErrorPage_button" onClick={() => history.push("/")}>
          Retour à l’accueil
        </button>
      </div>
    </>
  );
};

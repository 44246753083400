import React from "react";
import { Route, Redirect } from "react-router-dom";
import Store from "./Store/configStore";
import { connect } from "react-redux";
import { notify } from "./helpers/Notifications/Notifications";

const ProtectedRoute = ({ component: Component, path, does, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        rest.dispatch({
          type: "HANDLE_CURRENT_PATH",
          value: props.location.pathname,
        });
        if (Store?.getState()?.userData?.userData) {
          return <Component {...props} />;
        } else {
          notify({ msg: "Merci de vous authentifier", type: "warning" });
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    currentPathName: state.Auth.currentPathName,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GoCalendar, GoSearch } from "react-icons/go";
import { AiOutlineHeart } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import Store from "../../Store/configStore";
import "./StickyFooter.css";
import { notify } from "../Notifications/Notifications";
import { TOKEN_URL } from "../../config";

const StickyFooter = () => {
  const history = useHistory();
  const [isAuth, setAuth] = useState(true);

  useEffect(() => {
    if (Store?.getState()?.userData?.userData) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [Store?.getState()?.userData?.userData]);

  return (
    <div className="StickyFooter">
      <Link className="StickyFooter_icons" to="/search">
        <GoSearch
          size={20}
          className={
            window.location?.pathname === "/search" ||
            window.location?.pathname === "/search/results"
              ? "StickyFooter_selected"
              : ""
          }
        />
        <p
          className={
            window.location?.pathname === "/search" ||
            window.location?.pathname === "/search/results"
              ? "StickyFooter_icons_paragrapge StickyFooter_selected"
              : "StickyFooter_icons_paragrapge"
          }
        >
          Rechercher
        </p>
      </Link>
      {/* <Link className="StickyFooter_icons">
                <GoCalendar size={20} />
                <p className="StickyFooter_icons_paragrapge">Mes rendez-vous</p>
            </Link> */}
      {isAuth ? (
        <Link className="StickyFooter_icons" to="/profile/favorites">
          <AiOutlineHeart
            size={20}
            className={
              window.location?.pathname === "/profile/favorites"
                ? "StickyFooter_selected"
                : ""
            }
          />
          <p
            className={
              window.location?.pathname === "/profile/favorites"
                ? "StickyFooter_icons_paragrapge StickyFooter_selected"
                : "StickyFooter_icons_paragrapge"
            }
          >
            Mes favoris
          </p>
        </Link>
      ) : (
        <div
          style={{ width: "25%" }}
          onClick={() => {
            window.location.href = TOKEN_URL;
          }}
        >
          <Link className="StickyFooter_icons" style={{ width: "100%" }}>
            <AiOutlineHeart
              size={20}
              className={
                window.location?.pathname === "/profile/favorites"
                  ? "StickyFooter_selected"
                  : ""
              }
            />
            <p
              className={
                window.location?.pathname === "/profile/favorites"
                  ? "StickyFooter_icons_paragrapge StickyFooter_selected"
                  : "StickyFooter_icons_paragrapge"
              }
            >
              Mes favoris
            </p>
          </Link>{" "}
        </div>
      )}
      {isAuth ? (
        <Link className="StickyFooter_icons" to={"/profile"}>
          <FaRegUser
            size={20}
            className={
              window.location?.pathname === "/profile"
                ? "StickyFooter_selected"
                : ""
            }
          />
          <p
            className={
              window.location?.pathname === "/profile"
                ? "StickyFooter_icons_paragrapge StickyFooter_selected"
                : "StickyFooter_icons_paragrapge"
            }
          >
            Mon compte
          </p>
        </Link>
      ) : (
        <div
          style={{ width: "25%" }}
          onClick={() => {
            window.location.href = TOKEN_URL;
          }}
        >
          <Link className="StickyFooter_icons" style={{ width: "100%" }}>
            <FaRegUser
              size={20}
              className={
                window.location?.pathname === "/profile"
                  ? "StickyFooter_selected"
                  : ""
              }
            />
            <p
              className={
                window.location?.pathname === "/profile"
                  ? "StickyFooter_icons_paragrapge StickyFooter_selected"
                  : "StickyFooter_icons_paragrapge"
              }
            >
              Mon compte
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default StickyFooter;

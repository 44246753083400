var api_url = "https://api.chezledocteur.sobrus.ovh/api/";
var api_url_for_log_out = "https://api.chezledocteur.sobrus.ovh/";
let TOKEN_URL =
  "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10050&scope=openid%20offline_access&redirect_uri=https://api.chezledocteur.sobrus.ovh/auth/token&prompt=login";

if (process.env.REACT_APP_ENV === "development") {
  api_url = "https://api.chezledocteur.sobrus.ovh/api/";
  api_url_for_log_out = "https://api.chezledocteur.sobrus.ovh/";
  TOKEN_URL =
    "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10050&scope=openid%20offline_access&redirect_uri=https://api.chezledocteur.sobrus.ovh/auth/token&prompt=login";
}

if (process.env.REACT_APP_ENV === "production") {
  api_url = "https://api.chezledocteur.sobrus.com/api/";
  api_url_for_log_out = "https://api.chezledocteur.sobrus.com/";
  TOKEN_URL =
    "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10050&scope=openid%20offline_access&redirect_uri=https://api.chezledocteur.sobrus.com/auth/token&prompt=login";
}

export { api_url, TOKEN_URL, api_url_for_log_out };

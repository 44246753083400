import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "../../services/auth.service";
import { connect } from "react-redux";

class SuccessComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect_uri: "/",
      is_loaded: false,
    };
  }

  componentDidMount() {
    Auth.login(() => {
      this.setState({ is_loaded: true });
    });
  }

  render() {
    return this.state.is_loaded && <Redirect to={this.state.redirect_uri} />;
  }
}

const mapStateToProps = (state) => {
  return {
    currentPathName: state.Auth.currentPathName,
  };
};

export default connect(mapStateToProps)(SuccessComponent);

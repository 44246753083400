import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Store from "../../Store/configStore";
import "./UserProfileIfo.css";

export const UserProfileIfo = () => {
  const history = useHistory();
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    setAuthUser(Store?.getState()?.userData?.userData);
  }, []);

  return (
    <div className="UserProfileIfo">
      <div className="UserProfileIfo_header">
        <div className="UserProfileIfo_header_lil_container">
          <IoIosArrowBack
            size={20}
            color="#284358"
            onClick={() => history.goBack()}
          />
          <p className="UserProfileIfo_header_title">
            Informations personnelles
          </p>
        </div>
        {/* <p className="UserProfileIfo_header_update">Modifier</p> */}
      </div>
      <div className="UserProfileIfo_content_container">
        <div className="UserProfileIfo_content">
          <p className="UserProfileIfo_content_title">Nom</p>
          <p className="UserProfileIfo_content_text">
            {authUser?.lastName ?? ""}
          </p>
        </div>
        <div className="UserProfileIfo_content">
          <p className="UserProfileIfo_content_title">Prénom</p>
          <p className="UserProfileIfo_content_text">
            {authUser?.firstName ?? ""}
          </p>
        </div>
        <div className="UserProfileIfo_content">
          <p className="UserProfileIfo_content_title">Email</p>
          <p className="UserProfileIfo_content_text">{authUser?.email ?? ""}</p>
        </div>
        <div className="UserProfileIfo_content">
          <p className="UserProfileIfo_content_title">Numéro de téléphone</p>
          <p className="UserProfileIfo_content_text">
            {authUser?.phoneNumber ?? ""}
          </p>
        </div>
        <div className="UserProfileIfo_content">
          <p className="UserProfileIfo_content_title">Ville</p>
          <p className="UserProfileIfo_content_text">{authUser?.city ?? ""}</p>
        </div>
      </div>
    </div>
  );
};

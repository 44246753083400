import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { TOKEN_URL } from "../../config";
import Logo from "../../images/Logo.png";
import chezledocteur_logo from "../../images/chezledocteur.net_logo.png";
import question from "../../images/question.png";
import user from "../../images/user.png";
import Store from "../../Store/configStore";
import "./Header.css";

const Header = () => {
  const history = useHistory();
  const [isAuth, setAuth] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      const url = window.location.href;
      const newUrl = url.replace("https://m.", "https://");
      window.location.href = newUrl;
    }
    let userDAta = Store?.getState()?.userData?.userData;
    console.log("userDAta", userDAta);
    if (Store?.getState()?.userData?.userData) {
      setAuth(true);
    }
  }, []);

  return (
    <div className="header_container ">
      <div className="header">
        <div className="header_first_container">
          <img src={chezledocteur_logo} className="HeaderBurger" />
        </div>
        <div className="header_icons_conatainer">
          <div className="header_secend_container_for_first_icon">
            <img src={question} className="header_icons" />
          </div>
          <div
            className="header_secend_container_for_secend_icon"
            onClick={() => {
              if (isAuth) {
                history.push("/profile");
              } else {
                window.location.href = TOKEN_URL;
              }
            }}
          >
            <img src={user} className="header_icons" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

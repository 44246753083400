import axios from "axios";
import { api_url, api_url_for_log_out } from "../config";

const authAxios = axios.create({
  baseURL: api_url + "authenticated-user",
});

class AuthService {
  constructor() {
    this.authenticated = false;
  }

  getAuthenticatedUser() {
    return authAxios.get("", { withCredentials: true });
  }

  getAuthenticatedUserBookmarks() {
    return authAxios.get("/bookmarks", { withCredentials: true });
  }

  getAuthenticatedUserConsultations() {
    return authAxios.get("/consultations", { withCredentials: true });
  }

  login = (cb) => {
    localStorage.setItem("isLogged", true);
    this.authenticated = true;
    cb();
  };

  logOut() {
    localStorage.setItem("isLogged", false);
    this.authenticated = false;
    return axios.get(`${api_url_for_log_out}auth/revoke`, {
      withCredentials: true,
    });
  }

  isAuthenticated = () => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged) {
      return true;
    }
    return false;
  };
}

export default new AuthService();
